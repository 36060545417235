import type { ComponentProps } from 'react';
import React, { useCallback, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConvertToFolderSpotlight } from '@confluence/folder-onboarding';
import type { FormattedWebItem } from '@confluence/web-item-location';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import type { DialogsStateContainer } from '@confluence/dialogs';
import { ConvertPageToFolderDialog } from '@confluence/folder/entry-points/ConvertPageToFolderDialog';
import { useShowConvertPageToFolderBanner } from '@confluence/folder/entry-points/useShowConvertPageToFolderBanner';
import { fg } from '@confluence/feature-gating';

import { LazyContentToolsItem } from './LazyContentToolsItem';

export const ConvertToFolderItem = ({
	sectionItem,
	contentId,
	spaceKey,
	dialogs,
	...props
}: ConvertToFolderItemProps) => {
	const [isSpotlightOpen, setIsSpotlightOpen] = useState(true);

	const { canConvertPageToFolder, isPageFolderConversionCandidate, contentTitle } =
		useShowConvertPageToFolderBanner({
			contentId,
			spaceKey,
			skipQueryIfBannerDismissed: false,
		});

	const [isConvertToFolderOnboardingActive, stopConvertToFolderOnboarding] =
		useSkippableCoordination(
			'cc-convert-to-folder-onboarding',
			!fg('confluence_frontend_convert_to_folder_onboarding') || !isPageFolderConversionCandidate,
		);

	const handleSpotlightClose = useCallback(() => {
		setIsSpotlightOpen(false);
		void stopConvertToFolderOnboarding();
	}, [stopConvertToFolderOnboarding]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const openDialog = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'convertPageToFolder',
				source: 'contentToolsItem',
			},
		}).fire();
		dialogs.showDialog(ConvertPageToFolderDialog, {
			contentId,
			spaceKey,
			contentTitle,
		});
	};

	if (!canConvertPageToFolder) return null;

	sectionItem.url = '';
	sectionItem.urlWithoutContextPath = '';

	const convertToFolderItem = (
		<LazyContentToolsItem
			key={sectionItem.completeKey}
			{...sectionItem}
			{...props}
			onClick={openDialog}
			showBetaLozenge
		/>
	);
	if (
		isConvertToFolderOnboardingActive &&
		isSpotlightOpen &&
		fg('confluence_frontend_convert_to_folder_onboarding')
	) {
		return (
			<ConvertToFolderSpotlight onClose={handleSpotlightClose}>
				{convertToFolderItem}
			</ConvertToFolderSpotlight>
		);
	}
	return convertToFolderItem;
};

type ConvertToFolderItemProps = {
	sectionItem: FormattedWebItem;
	contentId: string;
	spaceKey: string;
	dialogs: DialogsStateContainer;
} & Partial<ComponentProps<typeof LazyContentToolsItem>>;
