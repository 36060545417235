import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';
import type {
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
} from '@confluence/content-unified-query';
import { ContentUnifiedQuery } from '@confluence/content-unified-query';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { useCanConvertPageToFolder } from './useCanConvertPageToFolder';
import { useConvertPageToFolderStore } from './ConvertPageToFolderStore';

const STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER =
	persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED;

type ConvertPageToFolderBannerProps = {
	contentId: string;
	spaceKey: string;
	skipQueryIfBannerDismissed?: boolean;
};

export const useShowConvertPageToFolderBanner = ({
	contentId,
	spaceKey,
	skipQueryIfBannerDismissed = true,
}: ConvertPageToFolderBannerProps) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const bannerDismissedKeyExists = Boolean(
		localStorage?.getItem(
			persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED,
		),
	);

	const [convertPageToFolderState, { showBanner, hideBanner, markBannerLoaded }] =
		useConvertPageToFolderStore();

	const skipQueries = skipQueryIfBannerDismissed && bannerDismissedKeyExists;

	const {
		canConvertPageToFolder,
		error: canConvertError,
		hasLoaded: hasLoadedCanConvert,
		isParent,
		contentTitle,
	} = useCanConvertPageToFolder({
		contentId,
		spaceKey,
		skipQuery: skipQueries,
	});

	const { data: contentUnifiedData, error: contentUnifiedError } = useQuery<
		ContentUnifiedQueryType,
		ContentUnifiedQueryVariablesType
	>(ContentUnifiedQuery, {
		skip: skipQueries,
		variables: {
			contentId,
			spaceKey,
			versionOverride: null,
			isSSR: Boolean(process.env.REACT_SSR),
			includeAlias: isSpaceAliasFFEnabled,
			useNewContentTopper: isNewContentTopperFFEnabled,
		},
	});

	const isPageFolderConversionCandidate =
		canConvertPageToFolder && checkBannerConditionsForPage(contentUnifiedData) && isParent;

	if (!bannerDismissedKeyExists && isPageFolderConversionCandidate) {
		showBanner();
	} else {
		hideBanner();
	}

	const handleOnClickClose = () => {
		localStorage?.setItem(STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER, true);
		hideBanner();
	};

	const error = canConvertError || contentUnifiedError;

	const hasLoaded = evalHasLoaded(
		contentUnifiedData,
		contentUnifiedError,
		bannerDismissedKeyExists,
		hasLoadedCanConvert,
	);

	useEffect(() => {
		markBannerLoaded(hasLoaded);
	}, [markBannerLoaded, hasLoaded]);

	return {
		showBanner: convertPageToFolderState.showConvertPageToFolderBanner,
		isPageFolderConversionCandidate,
		handleOnClickClose,
		error,
		canConvertPageToFolder,
		contentTitle,
	};
};

export const evalHasLoaded = (
	contentUnifiedData: ContentUnifiedQueryType | undefined,
	contentUnifiedError: ApolloError | undefined,
	bannerDismissedKeyExists: boolean,
	hasLoadedCanConvert: boolean,
) => {
	const hasCheckBannerConditionsLoaded = !!contentUnifiedData || !!contentUnifiedError;
	return bannerDismissedKeyExists || (hasLoadedCanConvert && hasCheckBannerConditionsLoaded);
};

const checkBannerConditionsForPage = (contentUnifiedData: ContentUnifiedQueryType | undefined) => {
	try {
		const dynamicValue = contentUnifiedData?.content?.nodes?.[0]?.body?.dynamic?.value;
		const documentADF = dynamicValue && JSON.parse(dynamicValue);

		// Returns true if page body is empty or only contains new lines, whitespace or Children / Page Tree macros
		const isEmptyOrHasTreeMacro = documentADF?.content?.every((node: any) => {
			// Return true for Children macro
			if (node?.type === 'extension' && node?.attrs?.extensionKey === 'children') {
				return true;
			}
			// Return true for Page Tree macro or whitespace and new lines
			if (node?.type === 'paragraph') {
				// Return true for new line
				if (!node?.content) {
					return true;
				}

				// Return true for Page tree macro or whitespace
				return node?.content?.every((node: any) => {
					if (node?.type === 'text' && node?.text.trim() === '') {
						return true;
					}
					if (node?.type === 'inlineExtension' && node?.attrs?.extensionKey === 'pagetree') {
						return true;
					}
					return false;
				});
			}
			return false;
		});

		return isEmptyOrHasTreeMacro;
	} catch {
		return false;
	}
};
